.MenuButton{
    margin-right: 20px;
    cursor: pointer;
}

.MenuButton i{
    font-size: xx-large;
    color: black;
}

.MenuButton span{
    font-size: 10px;
    color: black;
    font-weight: 600;
}