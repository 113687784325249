.content {
  display: grid;
  gap: 50px;
  justify-items: center;
  align-items: center;
  color: white;
  padding: 20px 0;
  width: 80%;
  max-width: 1062px;
  margin: 90px auto; 
  /* margin to clear the fixed top and bottom bar, needs refactoring */
}

.selection {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  width: 80%;
}

.total {
  font-size: 17px;
  font-weight: bold;
}

.pagination {
  background-color: #E0E0E0;
  padding: 4px;
  border-radius:5px;
  width: fit-content;
}

.dimensions {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 15px;
}