.cds-chartarea-main {
  display: grid;
  gap: 10px;
}

.cds-chartarea-section {
  display: grid;
  grid-template-columns: auto 1fr;
  background-color: #28231d;
  padding: 6px;
  border-radius: 5px;
}

.section-heading {
  writing-mode: vertical-rl;
  font-size: 11px;
  letter-spacing: 2px;
  line-height: 1em;
  margin-right: 5px;
}

.expandable {
  grid-column: 1/3;
  display: flex;
  justify-content: left;
  align-items: center;
}

.expandButton {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding: 0px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.expandOptionIcon {
  padding: 5px;
}

.selected {
  background-color: #3f3f4e;
}

.charts {
  display: grid;
  gap: 10px;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, minmax(min-content, 1fr));
}

.charts-expanded {
  display: grid;
  gap: 10px;
}

.chart-heading {
  font-size: 12px;
  line-height: 2em;
  text-align: center;
}

.chart-wrapper {
  height: 250px;
}
