.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
  }
  .overlay:target {
    visibility: visible;
    opacity: 1;
  }
  
  #langpopup {
    margin: 90px auto;
    padding: 10px;
    background: #E0E0E0;
    border-radius: 5px;
    width: 25%;
    position: relative;
    transition: all 5s ease-in-out;
  }
  
  #langpopup h3 {
    margin-top: 0;
    margin-bottom: 7px;
    color: #0d0d0d;
    font-family: Tahoma, Arial, sans-serif;
  }
  #langpopup .close {
    position: absolute;
    top: 2px;
    right: 12px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #0d0d0d;
    border-style:none;
    background-color: transparent;
  }
  #langpopup .close:hover {
    color: #bd2845;
    background-color:darkgray;
    cursor: pointer;
  }
  #langpopup .content {
    max-height: 30%;
    overflow: auto;
  }
  
  .languageList {
    background: #E0E0E0;
    padding: 0px;
    font-size: large;
  }

  #langpopup .content ul li {
    margin-bottom: 10px;
  }

  #langpopup .content ul li:hover {
      background: #a8a6a3;
      cursor: pointer;
    }


  @media screen and (max-width: 700px){
    .box{
      width: 70%;
    }
    .popup{
      width: 70%;
    }
  }