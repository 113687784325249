body {
  background-color: #282c34;
}

.display-inline-block {
  display: inline-block !important;
}

.inline-text-14-white {
  color: white;
  font-size: 14px !important;
  display: inline;
}

.inline-text-14-black {
  color: black;
  font-size: 14px !important;
}

.inline-text-14-black-inline {
  color: black;
  font-size: 14px !important;
  display: inline;
}

.inline-text-14-black-bold {
  color: black;
  font-size: 14px !important;
  display: inline;
  font-weight: bold !important;
}

.block-text-14-black-bold {
  color: black;
  font-size: 14px !important;
  font-weight: bold !important;
}

.inline-text-12-black {
  color: black;
  font-size: 12px !important;
  display: inline;
}

.inline-text-12-white {
  color: white;
  font-size: 12px !important;
  display: inline;
}

.inline-text-16-black {
  color: black;
  font-size: 16px !important;
}

.inline-text-16-white {
  display: inline !important;
  color: white;
  font-size: 16px !important;
}

.inline-text-16-white-bold {
  color: white;
  font-size: 16px !important;
  font-weight: bold !important;
  display: inline !important;
}

.grid-margin-5 {
  margin: 5px !important;
}

.grid-margin-10 {
  margin: 10px !important;
}

.font-weight-bold {
  font-weight: bold;
}

.font-size-14 {
  font-size: 14px;
}

.text-18-bold {
  font-size: 18px !important;
  font-weight: bold;
  color: black;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  top: 10px;
  margin-top: 90px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-alert-style {
  margin-top: 65px;
}

.app-cds-alert-style {
  margin-top: 91px;
}

.app-save-button {
  background-color: #5e9205 !important;
}

.app-cancel-button {
  background-color: #c50909 !important;
}

.app-remove-button {
  background-color: #0945c5 !important;
}

.app-copy-button {
  background-color: #1b39e6 !important;
  height: 25px !important;
  font-size: 13px !important;
}

.ahs-main-grid {
  text-align: left;
  width: 98% !important;
}

.ahs-data-grid {
  margin-top: 15px;
  width: 99%;
  z-index: 0;
  background-color: white;
}

.ahs-data-grid .MuiPaper-root {
  height: 71vh;
}

.ahs-data-grid thead th {
  font-size: 12px !important;
}

.ahs-data-grid tbody tr:nth-child(even){
  background-color:#deeefc;
}

.ahs-data-grid tbody tr:nth-child(odd){
  background-color:rgb(252, 252, 252);
}

.ahs-data-grid tbody tr{
  font-size: 14px !important;
}

.cds-ns-grid-cs-modal {
  width: 85% !important;
}

.cds-ns-grid-cs-modal-td {
  width: 55% !important;
  font-size: 12px !important;
  font-weight: bold !important;
}

.cds-ns-grid-cs-modal-button-div {
  width: 85% !important;
  text-align: right;
  
}

.cds-ns-main-heading-one {
  color: white !important;
  font-size: 12px !important;
  display: inline-block !important;
  /* margin-left: 7vw !important; */
}

.cds-ns-main-heading-two {
  color: white !important;
  font-size: 12px !important;
  display: inline-block !important;
  margin-left: 41vw !important;
}

.cds-ns-no-data-heading {
  width: 50% !important;
  text-align: center !important;
  color: white !important;
  margin: 0 auto !important;
}

.cds-ns-grid-value-col {
  width: 5%;
}

.cds-ca-chart-hr {
  margin-top: 25px;
  color: lightgray;
}

.searchform{
  width: 1200px;
  min-width: 900px;
  position: sticky;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: center;
  padding-top: 40px;
  bottom: auto;
  /* position: absolute; */
}

.input-ph{
  height: 70px;
  width: 150px;
  text-align: left;
  margin-right:  20px;
}

.input-ph text{
  font-size: 15px;
}

.input-ph-center{
  height: 120px;
  text-align: center;
  margin-right:  20px;
}
.input-ph-center text{
  font-size: 15px;
}

.input-button{
  background-color: #1f45fc;
  color: white;
  font-size: 16px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  height: 30px;
}

.grid-h2{
  height: 450px;
  margin-top: -25px !important;
  justify-content: center;
  max-width: 900px;
  min-width: 600px;
  padding-left: 10px;
  bottom: auto;
  visibility: hidden;
}

.loading-overlay{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; 
    background: rgba(0, 0, 0, 0.7);
    opacity:0.99;
    z-index: 100;
    /* transition: all 5s ease-in-out; */
    padding: 50px 0;
    display: none;
}

.wait{
    margin: auto;
    /* width: 50%; */
    /* border: 3px solid green; */
    padding: 75px 0;
    margin-top: 200px;
    height: 200px; 
    
    /* left: 0;
    line-height: 200px;
    margin-top: -100px;
    position: absolute; */
    text-align: center;
    font-size: 25px;
    /* top: 50%; */
    width: 70%;
    background-color: whitesmoke;
    color: black;
    z-index: 101;
    border-radius: 20px;
    opacity:1.0;
}

.error{
  margin: auto;
  /* width: 50%; */
  /* border: 3px solid green; */
  padding: 25px 0;
  margin-top: 100px;
  height: 350px; 
  
  
  text-align: center;
  font-size: 25px;
  
  width: 80%;
  background-color: whitesmoke;
  color: black;
  z-index: 101;
  border-radius: 20px;
  opacity:1.0;
}

.loading-overlay-small{
   
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0.5;
  z-index: 100;        
}

.loading-text-small{
  text-align: center;
  vertical-align: middle;
  line-height: 70px;
    font-size: 12px;
    top: 50%;
    width: 100%;
    color: white;
    z-index: 101;
}


.spc-table tr:hover{
  background-color: lightgray !important;
  color:black;
  cursor: pointer;
}

.spc-table tr{
  background-color: white !important;
  color: black;
}


.table-body{
  /* height: 170px; */
}

.table-header{
  background-color: white;
  color: black;
  font-size: 14px;
  font-weight: bold;
}

.CDSTable-header {
  background-color: #f78c09;
  color: black;
  font-size: 10px;
}

.CDSTable-header th {
  border: 1px solid black;
}

.CDSTable-body {
  color:white;
  font-size: 11px;
}

.cds-not-table, .cds-not-table tr, .cds-not-table td {
  border: 1px solid white;
  border-collapse: collapse;
}

.cds-not-table tr {
  height: 25px;
}

.CDSTable-body tr:hover {
  background-color: lightgray;
  color: black;
  cursor: pointer;
}

#cds_ca_remarks {
  width: 30vw;
}

#dataGrid{
  overflow:auto;
}

.table-head{
  width:20%;
}

.table-cell{
  width:20%;
  /* background-color: lightgray;
  color: black; */
}


.spc-table{
  width:100%;
  /* border-collapse: collapse; */
  font-size: 12px;
  padding: 15px;
}

.spc-header{
  width:100%;
  /* border-collapse: collapse; */
  padding: 15px;
}

.form-container{
  max-width: 1100px;
  min-width: 800px;
}

.spc_h4{
  font-size: 13px;
}

.spc_h3{
  font-size: 15px;
}

.spc-topbar-userprod-info {
  padding: 10px;
  text-align: left;
}

.square{
  position:absolute;
  top:50%;
  left:50%;
  margin-top:-150px; /* this is half the height of your div*/  
  margin-left:-150px; /*this is half of width of your div*/
  width: 300px;
  height: 250px;
  text-align: center;
  padding-top: 150px;
  background-color: yellow;
  display: block;
}

.square img{
  width: 50px;
  height: 50px;
}

.square-green{
  position:absolute;
  top:50%;
  left:50%;
  margin-top:-150px; /* this is half the height of your div*/  
  margin-left:-150px; /*this is half of width of your div*/
  background-color: green;
  width: 300px;
  height: 250px;
  text-align: center;
  padding-top: 150px;
  display: none;
}
.square-green text{
  color: white;
  font-size: 30px;
}

.cds-search-select {
  color: black;
  background-color: #E0E0E0;
  padding: 10px;
  border-radius: 5px;
  text-align: left;
  padding-left: 3.5vw;
}

.cds-chartarea-select-padding-margin {
  color: black;
  background-color: #E0E0E0;
  height: 50px;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 0px;
  text-align: left;
  margin-top: 10px;
  padding-left: 3.5vw;
}

.cds-pci-main-div {
  text-align: left !important;
}

.cds-pci-left-div-heading {
  font-size: 15px !important;
  margin-bottom: 3px !important;
  font-weight: bold !important;
  width: 93% !important;
  margin: 0px auto !important;
}

.cds-pci-left-div-top-heading {
  font-size: 17px !important;
  margin-bottom: 3px !important;
  font-weight: bold !important;
  width: 93% !important;
  margin: 0px auto !important;
}

.cds-pci-info {
  height: 100% !important;
  font-size: 12px !important;
  border-width: 1px !important;
  border-style: solid !important;
}

.cds-pci-info table {
  width:95% !important;
  margin: 0 auto !important;
}

.cds-pci-info tr {
  height: 24px;
}

.cds-pci-info td {
  width: 50%;
}

.cds-pci-histo-div {
  position: relative !important;
  height: 100% !important;
  border-width: 1px !important;
  border-style: solid !important;
}

.tcs-testitem-area {
  color: black;
  background-color: #E0E0E0;
  border-radius: 5px;
  text-align: left;
  padding-left: 10px;
  padding-bottom: 10px;
}

.tcs-test-red {
  border: 3px solid red;
  padding: 10px;
}

.tcs-test-orange {
  border: 3px solid orange;
  margin-top: 5px;
  padding: 10px;
}

.tcs-test-div {
  margin-top: 5px;
  padding: 5px;  
}

.tcs-test-div table {
  font-size: large;  
}

.tcs-test-div tr {
  font-size: large;  
}

.tcs-test-div th {
  font-size: large; 
  font-weight: 500;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px ;
  text-align: left;
  background-color: #0d0f0d;
  color: white;
}
 .tcs-test-div td{
  border: 0px solid rgb(119, 116, 116);
  padding: 3px;
 }

.tcs-test-yellow {
  border: 3px solid yellow;
  margin-top: 5px;
  padding: 10px;
}

.tcs-main-grid {
  text-align: left;
  width: 90% !important;
}

.tcs-grid-item-one-div {
  color: black !important;
  background-color: #E0E0E0;
  border: 1px solid white;
  padding: 20px;
  border-radius: 5px;
}

.tcs-button-table {
  width: 70%;
  margin-top: 10px;
}

.tcs-grid-two-label {
  color: black;
  font-size: 14px !important;
  font-weight: bold !important;
  display: inline;
  padding-top: 5px;
}

.ahs-data-table {
  font-size: 11px !important;
  background-color: #fffbf6 !important;
}

.cms-codelist-div {
  height: 200px;
  width: 350px;
  overflow: scroll;
  overflow-x: hidden;
  border: 1px solid white;
}

.cms-codelist-div span {
  display: block;
  font-size: 14px;
  padding: 5px;
}

.cms-codelist-div span:hover {
  background: lightgray;
  cursor: pointer;
  color: black;
}


#simple-modal-description table, #simple-modal-description tr, #simple-modal-description td {
  padding: 5px;
}

button:disabled,
button[disabled]{
  /* border: 1px solid #999999; */
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

div[disabled]
{
  pointer-events: none;
  opacity: 0.7;
}

span.text{
  font-size: 15px;
}

.ns-transfer-list-main-div {
  color:white;
  width: 100%;
  padding: 5px;
}

.ns-modal-main-heading {
  color: white !important;
  font-size: 14px !important;
  font-weight: bold !important;
  margin-bottom: 10px !important;
}

.ns-main-div {
  text-align: left;
  width: 65%;
  border: 1px solid white;
  padding: 20px;
}

.ns-main-div-title {
  color: white;
  font-size: 16px;
  display: inline;
  font-weight: bold;
}

.ns-button-div {
  text-align: right;
  cursor: pointer;
}

.ns-label-title {
  color: black;
  font-size: 14px;
}

.ns-label-title-bold {
  color: black;
  font-size: 14px;
  font-weight: bold;
}

.ns-label-formlabel-title {
  color: black;
  font-size: 14px;
  font-style: italic;
}

.ns-option-div {
  display:block;
  border: 1px solid black;
  padding-left: 5px;
}

.ns-search-email-button {
  cursor: pointer;
  font-size: 18px;
}

.languagepopup-alert-text {
  color: red;
  font-size: 12px;
  margin: 0px;
}

.languagepopup-alert-div {
  margin-bottom: 0px;
}

.cs-main-div {
  width: 70%;
}

#tl-add-but, #tl-remove-but {
  width: 6vw;
}

#tl-add-but[disabled], #tl-remove-but[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed !important;
}

.cms-main-div {
  text-align: left;
  width: 70%; 
  padding: 5px;
}

.cms-main-div .MuiPaper-root {
 height:80vh;
}

.cms-main-div tbody tr{
  font-size: 15px !important;
}

.cms-main-div tbody tr:nth-child(even){
  background-color:#deeefc;
}

.cms-main-div tbody tr:nth-child(odd){
  background-color:rgb(252, 252, 252);
}

@media screen and (max-width: 1025px) {
  .cs-main-div {
    width: 90%;
  }
} 

@media screen and (max-width: 759px) {
  .cs-main-div {
    width: 100%;
  }
} 


.react-dual-listbox {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}