form {
  display: grid;
  row-gap: 15px;
  box-sizing: border-box;
  padding: 0 30px 0 30px;
}

.form-item {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 15px;
  font-size: 12px;
  font-weight: bold;
  align-items: flex-end;
  box-sizing: border-box;
}

.form-label {
  font-weight: bold;
  font-size: 16px;
  text-align: right;
  align-self: center;
}

input[type="text"],
input[type="email"],
input[type="number"],
textarea {
  width : 100%;
  box-sizing: border-box;
}

h3 {
  border-bottom: 1px solid gray;
  padding-bottom: 10px;
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
}

.form-buttons > button {
  margin: 15px;
}

select:invalid {
  color: gray;
}

.loading-overlay-display {
  display: block;
}