.container {
  cursor: pointer;
  box-sizing: border-box;
}

.label {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
  font-size: 1.2rem;
  line-height: 1.5rem;
  box-sizing: border-box;
}

.graph {
  border: thin solid darkgray;
  max-width: 200px;
  box-sizing: border-box;
}

.nodata {
  padding: 5px;
  height: 92px;
  box-sizing: border-box;
  font-size: 1.5rem;
}